// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@popperjs/core")
require("jquery");

import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("@fortawesome/fontawesome-free");
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";

import { Tooltip, Popover } from "bootstrap"
document.addEventListener("turbolinks:load", () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import toastr from 'toastr'
window.toastr = toastr
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
