// src/controllers/contact_controller.js
import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["name", "email", "phone", "message","confirm", "divform",
        "nameerror", "emailerror", "phoneerror",
        "messageerror", "confirmerror","submitresponse", "submitcontainer"
    ];

    clearerror(){
        this.nameerrorTarget.textContent = '';
        this.emailerrorTarget.textContent = '';
        this.phoneerrorTarget.textContent = '';
        this.messageerrorTarget.textContent = '';
        this.confirmerrorTarget.textContent = '';
    }

    submit(e){
        e.preventDefault()
        let name = this.nameTarget.value;
        let email = this.emailTarget.value;
        let phone = this.phoneTarget.value;
        let message = this.messageTarget.value;
        let confirm = this.confirmTarget.checked;

        if(!name || !email || !this.validateEmail(email) || !phone || !message  ){
            if (!name) {
                this.nameerrorTarget.textContent = 'Voisitteko täyttää tämän';
            }else if(!email || !this.validateEmail(email)){
                this.emailerrorTarget.textContent = 'Voisitteko täyttää tämän';
            }else if(!phone){
                this.phoneerrorTarget.textContent = 'Voisitteko täyttää tämän';
            }else if(!message){
                this.messageerrorTarget.textContent = 'Voisitteko täyttää tämän';
            }
            // else if(!confirm){
            //     this.confirmerrorTarget.textContent = 'Voisitteko täyttää tämän';
            // }

            return;
        }

        Rails.ajax({
            type: "POST",
            url: `/contacts?full_name=${name}&email=${email}&phone=${phone}&message=${message}`,
            success: (_data, _status, xhr) => {
                let {status} = JSON.parse(xhr.response);
                this.submitcontainerTarget.style.display = 'block';
                this.submitresponseTarget.textContent = status;
                this.divformTarget.style.display = 'none';
                // this.emailTarget.style.display = 'none';
                // this.phoneTarget.style.display = 'none';
                // this.messageTarget.style.display = 'none';
                // this.confirmTarget.style.display = 'none';
                // this.nameTarget.style.display = 'none';
                // this.emailTarget.style.display = 'none';
                // this.phoneTarget.style.display = 'none';
                // this.messageTarget.style.display = 'none';
                // this.confirmTarget.style.display = 'none';
            },
            error: (_data, _status, xhr) => {
                const {status} = xhr.response;
                this.submitcontainerTarget.style.display = 'block';
                this.submitresponseTarget.classList.add("danger");
                this.submitresponseTarget.textContent = status;
            }
        });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
}
